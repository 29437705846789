import { createSlice } from '@reduxjs/toolkit';

const sharedSlice = createSlice({
    name: 'shared',
    initialState: {
        startYourSongModal: false,
        playingSongId: null,
        youtubeModalIsOpen: null as boolean | null,
        youtubeModalURL: null as string | null,
    },
    reducers: {
        setStartYourSongModal: (
            state,
            action
        ) => {
            state.startYourSongModal
                = action.payload
        },
        setPlayingSongId: (state,
            action
        ) => {
            state.playingSongId
                = action.payload
        },

        openYoutubeModal: (state,
            action
        ) => {
            state.youtubeModalURL = action.payload;
            state.youtubeModalIsOpen = true;
        },

        closeYoutubeModal: (state
        ) => {
            state.youtubeModalIsOpen = false;
        }

    },
});

export const {
    setStartYourSongModal,
    setPlayingSongId,
    openYoutubeModal,
    closeYoutubeModal,
} = sharedSlice.actions;

export default sharedSlice.reducer;