
const breakpoints: any = {
    values: {
        xs: 0,
        sm: 600,
        md1: 950,
        md: 900,
        lg: 1200,
        lg1: 1400,
        xl: 1536,
    }
}

export default breakpoints