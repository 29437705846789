import {
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react';
import { baseUrl, baseUrlLocal } from '~config/base-url';

const baseQuery = (baseQueryArgs = {}) => async (
    fetchArgs: any,
    api: any,
    extraOptions: any,
) => {

    return fetchBaseQuery({
        ...baseQueryArgs,
        baseUrl: process.env.NODE_ENV === 'production' ?
            baseUrl
            :
            baseUrlLocal,
        prepareHeaders: (headers, { getState }: any) => {

            const token = getState().auth.user?.access_token

            if (token)
                headers.set('authorization', `Bearer ${token}`)

            return headers
        },
    })(fetchArgs, api, extraOptions);
};

export const api = createApi({
    tagTypes: [
        'Topic',
        'Order',
        'AISongs',
        'Artist',
        'Balance',
        'Refferal',
        'Occasion',
        'OrderDetail',
        'ExtraCharge',
        'Relationship',
        'PreviousOrders',
        'OccasionDetails',
        'CustomOrderSummaries',
        'PreviousOrderSummaries',
    ],
    baseQuery: baseQuery(),
    endpoints: () => ({}),
})