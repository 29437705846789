const baseUrl = process.env.NEXT_PUBLIC_APP_BASE_URL
const baseUrlLocal = process.env.NEXT_PUBLIC_APP_BASE_URL_LOCAL
const webBaseUrl = process.env.NEXT_PUBLIC_APP_WEB_BASE_URL
const cloudinaryBaseUrl = process.env.NEXT_PUBLIC_APP_CLOUDINARY_BASE_URL

export {
    baseUrl,
    webBaseUrl,
    baseUrlLocal,
    cloudinaryBaseUrl,
}
