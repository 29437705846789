function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/';

export const PATH_AUTH: any = {
  login: '/login',
  resetPassword: '/reset-password',
  newPassword: '/new-password',
};

export const PATH_SCREEN: any = {
  orderDeliveryTime: path(ROOTS_DASHBOARD, '/order-delivery-time'),
  congratulations: path(ROOTS_DASHBOARD, '/congratulations'),
  pickYourArtist: path(ROOTS_DASHBOARD, '/order-step-2-artist'),
  orderArtist: path(ROOTS_DASHBOARD, '/order-step-2-artist-1')

};
export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};




export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  dashboard: path(ROOTS_DASHBOARD, '/'),
  inbox: path(ROOTS_DASHBOARD, '/inbox'),
  orders: path(ROOTS_DASHBOARD, '/orders'),
  customers: path(ROOTS_DASHBOARD, '/customers'),
  artists: path(ROOTS_DASHBOARD, '/artists'),
  employees: path(ROOTS_DASHBOARD, '/employees'),
  coupons: path(ROOTS_DASHBOARD, '/coupons'),
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
};
