import {
    configureStore
} from '@reduxjs/toolkit'
import { setAutoFreeze } from 'immer'
import {
    combineReducers
} from "redux"
import {
    persistReducer
} from 'redux-persist'
import auth from '~screens/auth/store/authSlice'
import order from '~screens/order/store/orderSlice'
import shared from '~screens/shared/store/sharedSlice'
import {
    api
} from '~services/index'
import storage from '~store/storage'
import {
    persistStore
} from 'redux-persist'

setAutoFreeze(false)

const combinedReducer = combineReducers({
    auth,
    order,
    shared,
    [api.reducerPath]: api.reducer,
})

const rootReducer = (
    state: any,
    action: any
) => {
    if (action.type === 'auth/logout') { // check for action type 
        state = undefined;
    }
    return combinedReducer(state, action);
};

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'order'],
}

const persistedReducer
    = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    devTools:
        process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }).concat(
            api.middleware,
        ),
})

export default store
export type IRootState = ReturnType<typeof rootReducer>
export const persistor = persistStore(store);
