
const enums: any = {
    traitTypes: [
        "Genre",
        "Beat",
        "Feel",
    ],
    gender: [
        "Male",
        "Female",
    ],
    orderStatuses: [
        "Draft",
        "Pending",
        "InProduction",
        "Complete",
        "Approved",
        "OnHold",
        "Cancelled",
        "InReview",
        "Revision",
    ],
    addonTypeName: [
        "Playback",
        "AdditionalVerse",
        "ArtistTip",
        "AudioGreeting",
        "LyricsSheet",
    ],
    clientChoiceStatus: [
        "Video",
        "Song",
    ]
}

export default enums