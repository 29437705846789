import {
  CacheProvider,
  EmotionCache
} from '@emotion/react'
import {
  NextPage
} from 'next'
import {
  AppProps
} from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import {
  Toaster
} from 'react-hot-toast'
import 'react-lazy-load-image-component/src/effects/blur.css'
import {
  Provider,
  useSelector
} from 'react-redux'
import {
  PersistGate
} from 'redux-persist/integration/react'
import 'simplebar-react/dist/simplebar.min.css'
import {
  MotionLazyContainer
} from '~components/animate'
import ProgressBar from '~components/progress-bar'
import {
  SettingsProvider,
  ThemeSettings
} from '~components/settings'
import SnackbarProvider from '~components/snackbar'
import {
  AuthProvider
} from '~config/auth/JwtContext'
import {
  satoshi
} from '~config/fonts'
import ThemeLocalization from '~config/locales'
import '~config/locales/i18n'
import ThemeProvider from '~config/theme'
import createEmotionCache from '~config/utils/createEmotionCache'
import { ModalDialogsProvider } from '~screens/shared/modal-dialogs/modal-dialogs-provider'
import store, { persistor } from '~store/index'
import '~styles/global.css'
import { useEffect, useState } from 'react'
import { getReferralId, trackIdentifyUser, trackVirtualPageVisit, getUTM, getMarketingDebugData } from '~services/tagging'
//import LogRocket from 'logrocket';



const clientSideEmotionCache = createEmotionCache()

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode
}

export function Pagesense() {
  const {
    user,
  } = useSelector((
    state: any
  ) => state.auth)

  useEffect(() => {
    const email = (user?.userDto?.role?.name === 'Visitor' || !user) ? user?.userDto?.gifterEmail : user?.userDto?.email;
    if (email) {
      trackIdentifyUser(email)

      // // suport
      // LogRocket.identify(user?.userDto?._id||'', {
      //   name: `${user?.userDto?.firstName||''} ${user?.userDto?.lastName||''}`.trim()||"na",
      //   email: email,
      //   subscriptionType: user?.userDto?.role?.name||"na"
      // });
      // LogRocket.identify(user?.userDto?._id||'', {
      //   name: `${user?.userDto?.firstName||''} ${user?.userDto?.lastName||''}`.trim()||"na",
      //   email: email,
      //   subscriptionType: user?.userDto?.role?.name||"na"
      // });
      if ((window as any)?.clarity)
        (window as any).clarity("identify", email)
    }
  }, [user?.userDto?.role?.name, user?.userDto?.gifterEmail, user?.userDto?.email]);
  return null;
}

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
  Component: NextPageWithLayout
}

export default function MyApp(props: MyAppProps) {
  const {
    Component,
    pageProps,
    emotionCache = clientSideEmotionCache
  } = props

  const router = useRouter();

  useEffect(() => {
    getReferralId();
    getUTM();
    getMarketingDebugData();
    // LogRocket.init('mfvxmi/order');
  }, [])

  useEffect(() => {
    trackVirtualPageVisit()
  }, [router.asPath])

  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <main
      className={
        satoshi.className
      }
    >
      <CacheProvider value={emotionCache}>
        <Head>
          <meta
            name="viewport"
            content="initial-scale=1, width=device-width"
          />
        </Head>
        <AuthProvider>
          <ModalDialogsProvider>
            <SettingsProvider>
              <MotionLazyContainer>
                <ThemeProvider>
                  <Provider {...{ store }}>
                    <ThemeSettings>
                      <ThemeLocalization>
                        <SnackbarProvider>
                          <ProgressBar />
                          {getLayout(
                            <Component
                              {...pageProps}
                            />
                          )}
                        </SnackbarProvider>
                      </ThemeLocalization>
                    </ThemeSettings>
                    <Pagesense />
                  </Provider>
                </ThemeProvider>
              </MotionLazyContainer>
            </SettingsProvider>
          </ModalDialogsProvider>
        </AuthProvider>
      </CacheProvider>
      <Toaster
        position='top-right'
      />
    </main>
  )
}
