import { api } from '~services/index'

export const accountApi
    = api.injectEndpoints({
        endpoints: (builder) => ({

            referral: builder.query({
                query: () => ({
                    url: `/api/referral`
                }),
                providesTags: ['Refferal'],
            }),
            balance: builder.query({
                query: () => ({
                    url: `/api/balance`
                }),
                providesTags: ['Balance'],
            }),
            country: builder.query({
                query: () => ({
                    url: `/api/country`
                }),
            }),
            paymentMethod: builder.query({
                query: () => ({
                    url: `/api/payment/payment-method`
                }),
            }),
            billingAddress: builder.query({
                query: () => ({
                    url: `/api/payment/billing-address`
                }),
            }),
            validateCcValidity: builder.mutation({
                query: (body) => {
                    return {
                        url: `/api/icount/validate-cc-validity`,
                        method: 'PATCH',
                        body,
                    }
                },
            }),
            validateCcNumber: builder.mutation({
                query: (body) => {
                    return {
                        url: `/api/icount/validate-cc-number`,
                        method: 'PATCH',
                        body,
                    }
                },
            }),
            detectCcType: builder.mutation({
                query: (body) => {
                    return {
                        url: `/api/icount/detect-cc-type`,
                        method: 'PATCH',
                        body,
                    }
                },
            }),
            ccTypes: builder.query({
                query: () => ({
                    url: `/api/icount/cc-types`,
                    method: 'GET'
                }),
            }),
            updateUserDetails: builder.mutation({
                query: (body) => {
                    return {
                        url: `/api/user/update-user-details`,
                        method: 'POST',
                        body,
                    }
                },
            }),
        }),
    })

export const {
    useCcTypesQuery,
    useBalanceQuery,
    useCountryQuery,
    useReferralQuery,
    usePaymentMethodQuery,
    useBillingAddressQuery,
    useDetectCcTypeMutation,
    useValidateCcNumberMutation,
    useUpdateUserDetailsMutation,
    useValidateCcValidityMutation,
} = accountApi


