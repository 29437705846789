import React, { createContext, useState, useContext, useCallback, ReactNode, SetStateAction, Dispatch } from 'react';

interface ModalDialogsContextType {
    isLoginDialogOpen: boolean;
    isRegisterDialogOpen: boolean;
    isInOrder: boolean;
    isInHome: boolean;
    showLoginDialog: () => void;
    showRegisterDialog: () => void;
    hideDialogs: () => void;
    triggerRegistrationSuccessCallback: () => void;
    setRegistrationSuccessCallback: (callback: (() => void) | null) => void;
    setIsInOrder: (isInOrder: boolean) => void;
    setIsInHome: (isInHome: boolean) => void;
    triggerHideDialogsCallback: () => void; // Added
    setHideDialogsCallback: (callback: (() => void) | null) => void; // Added
}

const ModalDialogsContext = createContext<ModalDialogsContextType>(null!);

export const useModalDialogs = () => useContext(ModalDialogsContext) as ModalDialogsContextType;

interface ModalDialogsProviderProps {
    children: ReactNode;
}

export const ModalDialogsProvider: React.FC<ModalDialogsProviderProps> = ({ children }) => {
    const [isLoginDialogOpen, setLoginDialogOpen] = useState(false);
    const [isRegisterDialogOpen, setRegisterDialogOpen] = useState(false);
    const [isInOrder, setIsInOrder] = useState(false);
    const [isInHome, setIsInHome] = useState(false);
    const [registrationSuccessCallback, setRegistrationSuccessCallback1] = useState<(() => void) | null>(null);
    const [hideDialogsCallback, setHideDialogsCallback1] = useState<(() => void) | null>(null);

    const setRegistrationSuccessCallback = (callback: (() => void) | null) => setRegistrationSuccessCallback1(() => callback);
    const setHideDialogsCallback = (callback: (() => void) | null) => setHideDialogsCallback1(() => callback);

    const showLoginDialog = useCallback(() => {
        setLoginDialogOpen(true);
        setRegisterDialogOpen(false);
    }, []);

    const showRegisterDialog = useCallback(() => {
        setRegisterDialogOpen(true);
        setLoginDialogOpen(false);
    }, []);

    const hideDialogs = useCallback(() => {
        setLoginDialogOpen(false);
        setRegisterDialogOpen(false);
        if (hideDialogsCallback) {
            hideDialogsCallback();
        }
    }, [hideDialogsCallback]);

    const triggerRegistrationSuccessCallback = useCallback(() => {
        if (registrationSuccessCallback) {
            registrationSuccessCallback();
            setRegistrationSuccessCallback(null);
        }
    }, [registrationSuccessCallback]);

    const triggerHideDialogsCallback = useCallback(() => { // Added
        if (hideDialogsCallback) {
            hideDialogsCallback();
            // setHideDialogsCallback(null); // Optionally reset the callback after invoking
        }
    }, [hideDialogsCallback]);

    return (
        <ModalDialogsContext.Provider value={{
            isLoginDialogOpen,
            isRegisterDialogOpen,
            isInOrder,
            isInHome,
            showLoginDialog,
            showRegisterDialog,
            hideDialogs,
            triggerRegistrationSuccessCallback,
            setRegistrationSuccessCallback,
            setIsInOrder,
            setIsInHome,
            triggerHideDialogsCallback,
            setHideDialogsCallback,
        }}>
            {children}
        </ModalDialogsContext.Provider>
    );
};

/*
app example:


import React from 'react';
import { ModalDialogsProvider } from '../../shared/modal-dialogs/modal-dialogs-provider';
// ... other imports

function App() {
    return (
        <ModalDialogsProvider>
            {
                // rest of your app 
            }
        </ModalDialogsProvider>
    );
}

export default App;
*/


/*
usage example

import React from 'react';
import { useModalDialogs } from '../../shared/modal-dialogs/modal-dialogs-provider';
import LoginDialog from '~components/login-dialog';
import RegisterDialog from '~components/register-dialog';

const SomeComponent = () => {
    const { isLoginDialogOpen, isRegisterDialogOpen, showLoginDialog, showRegisterDialog, hideDialogs } = useModalDialogs();

    return (
        <div>
            <button onClick={showLoginDialog}>Login</button>
            <button onClick={showRegisterDialog}>Register</button>
            <LoginDialog open={isLoginDialogOpen} onClose={hideDialogs} />
            <RegisterDialog open={isRegisterDialogOpen} onClose={hideDialogs} />
        </div>
    );
};

export default SomeComponent;


*/